
    import {Component, Vue} from 'vue-property-decorator';
    import {Foundation} from '@/utils/Foundation';
    import API_MerchantList from '@/utils/API/merchant/MerchantList';
    import API_WxReconciliation from '@/utils/API/finance/WxReconciliation';
    import {_wxReconciliation} from "@/utils/pageConfig/finance/_wxReconciliation";
    import editWxRec from "./editWxRec.vue";

    @Component({
        components: {
          editWxRec
        },
    })
    export default class WxReconciliation extends Vue {
        pageConfig: any = _wxReconciliation;
        tableData: any = [];
        merchantList: any = [];
        total: number = 0;
        params: any = {
            current: 1,
            size: 20,
        };
        exportForm: any = {
            type: {
                tag: "ADMIN_FINANCE_CONFIRM_EXPORT",
                url: "/core/api/v1/admin/c/export/exec/finance/confirm/record"
            },
            fileName: "月终对账表"
        };
        isLoading: boolean = false;

        async mounted() {
            await this.getAllMerchant();
            this.handleSearch();
        }

        async getAllMerchant(){
            // 搜索框商户列表
            let res : any = await API_MerchantList.getAllMerchant();
            res.map((e : any) => {
                this.merchantList.push({label: e.coName, value: e.id});
            });
            this.pageConfig.searchMonth[0].options = this.merchantList;
        }

        /*获取表格数据*/
        async getTableData() {
            let res: any = await API_WxReconciliation.getRecList(this.params);
            this.tableData = (res.records && res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._systemSendTotalAmount = Foundation.toYUAN(e.systemSendTotalAmount);
                    e._wxSendTotalAmount = Foundation.toYUAN(e.wxSendTotalAmount);
                    e._confirmResult = ["对账异常", "对账正确"][e.confirmResult];
                    e._operateType = ["系统对账", "人工对账"][e.operateType];
                    e._createTime = Foundation.dateFormat(e.createTime);
                    e._updateTime = Foundation.dateFormat(e.updateTime);
                    return e
                })) || [];
            this.total = res.total;
        }

        /*底部分页切换*/
        currentChange(pageNumber: number) {
            this.params.current = pageNumber;
            this.getTableData();
        }

        /*底部分页条数切换*/
        sizeChange(sizeNumber: number) {
            this.params.size = sizeNumber;
            this.getTableData();
        }

        /*查询*/
        handleSearch() {
            let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
            query = Foundation.resetDate(query, false, ["confirmBeginDate", "confirmEndDate"]);
            query.confirmType = 1;
            this.exportForm.condition = query;
            this.params = {
                current: 1,
                size: 20,
                ...query,
            };
            this.getTableData();
        }

        summaryMethod(param: any){
            const { data } = param;
            let sums: any = ['合计：', '', '**', '**'];
            const systemSendTotalAmount = data.map((item: any) => Number(item.systemSendTotalAmount));
            const wxSendTotalAmount = data.map((item: any) => Number(item.wxSendTotalAmount));
            sums[2] = Foundation.toYUAN(systemSendTotalAmount.reduce((prev: any, curr: any) => {return prev + curr}, 0));
            sums[3] =  Foundation.toYUAN(wxSendTotalAmount.reduce((prev: any, curr: any) => {return prev + curr}, 0));
            return sums;
        }

        /*对账*/
        handleCheck(data: any){
            Foundation.beforeDelete(async () => {
                if (this.isLoading) return;
                this.isLoading = true;
                const res: any = await API_WxReconciliation.checkRec({id: data.id});
                this.isLoading = false;
                this.$message.success("操作成功");
                this.params.current = 1;
                this.getTableData();
            }, '确定对账吗?', '已取消');
        }

        /*异常明细*/
        handleException(data: any){
            let begin: string = data.confirmDate + "-01";
            let beginDate: any = new Date(begin);
            let endDate: any = new Date(beginDate.getFullYear(), beginDate.getMonth() + 1, 0).getTime();
            let end: string = Foundation.dateFormat(endDate, "YYYY-MM-DD");
            this.$emit("changeTab", 'day', {date: [begin, end], merchantId: data.merchantId, confirmResult: 0});
        }

        /*编辑*/
        handleEdit(data: any){
            (this.$refs.editWxRec as any).show({id: data.id, remark: data.remark || ""});
        }

        /*导出*/
        handleExport() {
            (this.$refs.exports as any).show(this.exportForm);
        }
    }
