
import { Component, Vue } from "vue-property-decorator";
import reconciliationDay from "./components/reconciliationDay.vue";
import reconciliationMonth from "./components/reconciliationMonth.vue";

@Component({
  components: {
    reconciliationDay,
    reconciliationMonth
  },
})
export default class Index extends Vue {
  tabName: string = "day";
  dayParams: any = {};

  changeTab(tabName: string, params: any){
      this.tabName = tabName;
      this.dayParams = params;
  }
}
