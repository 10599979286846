export const _wxReconciliation = {
    //筛选
    searchDay: [
        {
            type: 'el-select',
            label: '商户名称：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-select',
            label: '对账结果：',
            value: 'confirmResult',
            props: {
                placeholder: '请选择对账结果',
                clearable: true
            },
            options: [
                {
                    label: '对账异常',
                    value: 0
                },
                {
                    label: '对账正确',
                    value: 1
                }
            ]
        },
        {
            type: 'el-date-picker',
            label: '对账日期：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'value-format': "yyyy-MM-dd"
            }
        },
    ],
    //列表
    tableDay: [
        {
            prop: 'confirmDate',
            label: '年月日对账日期'
        },
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            prop: '_systemSendTotalAmount',
            label: '系统发放金额'
        },
        {
            prop: '_wxSendTotalAmount',
            label: '微信发放金额'
        },
        {
            prop: '_confirmResult',
            label: '对账结果'
        },
        {
            prop: '_operateType',
            label: '确认方式'
        },
        {
            prop: 'remark',
            label: '备注'
        },
        {
            prop: '_createTime',
            label: '创建时间'
        },
        {
            prop: '_updateTime',
            label: '更新时间'
        },
        {
            label: '操作',
            width: "160px",
            fixed: "right",
            self: {
                body: 'operation'
            }
        }
    ],
    //筛选
    searchMonth: [
        {
            type: 'el-select',
            label: '商户名称：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
        {
            type: 'el-select',
            label: '对账结果：',
            value: 'confirmResult',
            props: {
                placeholder: '请选择对账结果',
                clearable: true
            },
            options: [
                {
                    label: '对账异常',
                    value: 0
                },
                {
                    label: '对账正确',
                    value: 1
                }
            ]
        },
        {
            type: 'el-date-picker',
            label: '对账日期：',
            value: 'date',
            props: {
                type: 'daterange',
                'range-separator': '至',
                'start-placeholder': '开始日期',
                'end-placeholder': '结束日期',
                'value-format': "yyyy-MM-dd"
            }
        },
    ],
    //列表
    tableMonth: [
        {
            prop: 'confirmDate',
            label: '年月对账日期'
        },
        {
            prop: 'merchantName',
            label: '商户名称'
        },
        {
            prop: '_systemSendTotalAmount',
            label: '月度系统发放金额'
        },
        {
            prop: '_wxSendTotalAmount',
            label: '月度微信发放金额'
        },
        {
            prop: '_confirmResult',
            label: '对账结果'
        },
        {
            prop: '_operateType',
            label: '确认方式'
        },
        {
            prop: 'remark',
            label: '备注'
        },
        {
            prop: '_createTime',
            label: '创建时间'
        },
        {
            prop: '_updateTime',
            label: '更新时间'
        },
        {
            label: '操作',
            width: "160px",
            fixed: "right",
            self: {
                body: 'operation'
            }
        }
    ],
    //编辑表单
    editForm: [
        {
            type: 'el-input',
            label: '备注：',
            value: 'remark',
            props: {
                type: 'textarea',
                rows: '4',
                clearable: true
            }
        }
    ],
    //日终异常明细筛选
    searchExceptionDay: [
        {
            type: 'el-input',
            label: '批次号：',
            value: 'batchOrderNo',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
        {
            type: 'el-input',
            label: '明细订单号：',
            value: 'detailOrderNo',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
    ],
    //日终异常明细
    tableExceptionDay: [
        {
            prop: 'batchOrderNo',
            label: '批次号'
        },
        {
            prop: 'detailOrderNo',
            label: '明细订单号'
        },
        {
            prop: '_amount',
            label: '扣款金额'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
    //月终异常明细筛选
    searchExceptionMonth: [
        {
            type: 'el-input',
            label: '微信订单号：',
            value: 'batchWxOrderId',
            props: {
                placeholder: '请输入',
                clearable: true
            },
        },
    ],
    //月终异常明细
    tableExceptionMonth: [
        {
            prop: 'confirmDate',
            label: '异常日期'
        },
        {
            prop: 'merchantName',
            label: '异常数量'
        },
        {
            prop: '_amount',
            label: '扣款金额'
        },
        {
            prop: '_date',
            label: '异常原因'
        },
        {
            label: '操作',
            self: {
                body: 'operation'
            }
        }
    ],
};

