
    import {Component, Vue} from 'vue-property-decorator';
    import {_wxReconciliation} from "@/utils/pageConfig/finance/_wxReconciliation";
    import {Foundation} from '@/utils/Foundation';
    import API_WxReconciliation from '@/utils/API/finance/WxReconciliation';

    @Component({
        name: 'editWxRec',
    })
    export default class extends Vue {
        pageConfig: any = _wxReconciliation;
        isLoading: boolean = false;

        show(data: any) {
            (this.$refs.editWxRec as any).show();
            this.$nextTick(() => {
                (this.$refs.editForm as any).form = Foundation.deepClone(data);
                (this.$refs.editForm as any).$refs.form.clearValidate();
            });
        }

        hide() {
            (this.$refs.editWxRec as any).hide();
        }

        async submitForm() {
            let form = Foundation.resetParams((this.$refs.editForm as any).form);

            (this.$refs.editForm as any).$refs.form.validate(async (valid: any) => {
                if (valid) {
                    if (this.isLoading) return;
                    this.isLoading = true;
                    try {
                        await API_WxReconciliation.editRemark(form);
                        this.isLoading = false;
                        this.$message.success('操作成功');
                        this.hide();
                        this.$emit('submitForm', form);
                    }catch (e) {
                        this.isLoading = false;
                    }
                } else {
                    return false;
                }
            });
        }
    }
