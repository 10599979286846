
    import {Component, Vue} from 'vue-property-decorator';
    import {Foundation} from '@/utils/Foundation';
    import API_WxReconciliation from '@/utils/API/finance/WxReconciliation';
    import {_wxReconciliation} from "@/utils/pageConfig/finance/_wxReconciliation";

    @Component({
        components: {},
    })
    export default class ExceptionDay extends Vue {
        pageConfig: any = _wxReconciliation;
        tableData: any = [];
        total: number = 0;
        params: any = {
            current: 1,
            size: 20,
        };
        isLoading: boolean = false;
        data: any = {};

        show(data: any) {
            (this.$refs.exceptionDay as any).show();
            this.data = data;
            this.$nextTick(()=>{
              this.handleSearch();
            })
        }

        hide() {
            (this.$refs.exceptionDay as any).hide();
        }

        /*获取表格数据*/
        async getTableData() {
            this.params.id = this.data?.id || "";
            let res: any = await API_WxReconciliation.getExceptionDay(this.params);
            this.tableData = (res.records && res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._amount = Foundation.toYUAN(e.amount);
                    return e
                })) || [];
            this.total = res.total;
        }

        /*底部分页切换*/
        currentChange(pageNumber: number) {
            this.params.current = pageNumber;
            this.getTableData();
        }

        /*底部分页条数切换*/
        sizeChange(sizeNumber: number) {
            this.params.size = sizeNumber;
            this.getTableData();
        }

        /*查询*/
        handleSearch() {
            let query: any = Foundation.resetParams((this.$refs.searchForm as any).form, true);
            query = Foundation.resetDate(query);
            this.params = {
                current: 1,
                size: 20,
                ...query,
            };
            this.getTableData();
        }

        /*同步状态*/
        handleStatus(row: any){
            Foundation.beforeDelete(async () => {
                if (this.isLoading) return;
                this.isLoading = true;
                const res: any = await API_WxReconciliation.setStatusException({id: row.id, merchantId: this.data.merchantId});
                this.isLoading = false;
                this.$message.success("操作成功");
                this.params.current = 1;
                this.getTableData();
            }, '确定同步状态吗?', '已取消');
        }
    }
