import Request from "../Request";

export default class WxReconciliation {
    public static baseUrl = '/core/api/v1/admin/b/finance';

    /*微信对账表*/
    public static getRecList(params: any) {
        return Request({
            url: `${this.baseUrl}/confirm/page`,
            method: "get",
            params
        });
    }

    /*对账*/
    public static checkRec(data: any) {
        return Request({
            url: `${this.baseUrl}/confirm/again`,
            method: "post",
            data
        });
    }

    /*编辑备注*/
    public static editRemark(data: any) {
        return Request({
            url: `${this.baseUrl}/remark`,
            method: "post",
            data
        });
    }

    /*日终异常*/
    public static getExceptionDay(params: any) {
        return Request({
            url: `${this.baseUrl}/exception/day/wxpay/record`,
            method: "get",
            params
        });
    }

    /*同步状态*/
    public static setStatusException(data: any) {
        return Request({
            url: `${this.baseUrl}/updateWxConfirmResult`,
            method: "post",
            data
        });
    }
}
